.gear-wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.image-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
}